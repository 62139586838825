<template>
    <div>
        <page-header
            :title="$t('pages.AddBankAccount.AddBanksAccount')"
            :show-close="true"
            @onClose="$router.go(-1)"
        />
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <form-container
            @onSubmit="validateAndSubmit"
            ref="bankDetailForm"
            class="container"
        >
            <div class="mb-2">
                <div class="input">
                    <select
                        name="bankAccountType"
                        v-model="bankAccountType"
                        class="form-control"
                    >
                        <option
                            v-for="type in BankAccountTypes"
                            :key="type.value"
                            :value="type.value"
                        >
                            {{ type.name }}
                        </option>
                    </select>
                </div>
            </div>

            <img
                src="../assets/check-bottom-clipped.jpg"
                alt="Contact your bank for your routing and account number"
                class="w-100"
            >

            <form-field
                v-model="routingNumber"
                container-classes="mt-3"
                input-type="tel"
                inputmode="decimal"
                ref="routing"
                name="routing"
                validation="required|min:9|max:9|numeric"
                validation-mode="aggressive"
                :max-length="9"
                :placeholder="$t(`pages.AddBankAccount.RoutingNumber`)"
            />
            <ValidationObserver>
                <form-field
                    v-model="bankAccountNumber"
                    input-type="tel"
                    inputmode="decimal"
                    name="accountnumber"
                    ref="accountnumber"
                    validation="required|min:6|max:17|numeric"
                    validation-mode="aggressive"
                    :max-length="17"
                    :placeholder="$t(`pages.AddBankAccount.AccountHolderNumber`)"
                />

                <form-field
                    v-model="confirmBankAccountNumber"
                    input-type="tel"
                    inputmode="decimal"
                    name="confirmAccountNumber"
                    ref="confirmAccountNumber"
                    validation="required|min:6|max:17|numeric|confirmAccountNumber:@accountnumber"
                    validation-mode="aggressive"
                    :max-length="17"
                    :placeholder="$t(`pages.AddBankAccount.ConfirmAccountHolderNumber`)"
                />
            </ValidationObserver>
            <base-button
                type="submit"
                class="mt-3"
                :submitting="submitting"
            >
                {{ $t(`pages.AddBankAccount.AddBankAccount`) }}
            </base-button>
        </form-container>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { BankAccountTypes } from '../data/constants'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import FormContainer from '@/components/base/FormContainer'
    import FormField from '@/components/base/FormField'
    import BaseButton from '@/components/base/BaseButton'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import startCase from 'lodash/startCase'
    import { i18n } from '@/utils/i18n'
    import { mapActions } from 'vuex'

    export default {
        name: 'PaymentSourceForm',
        components: {
            FormField,
            PageHeader,
            FormContainer,
            BaseButton,
            LoadingIndicator,
        },
        data() {
            return {
                loading: false,
                error: false,
                errorText: '',
                BankAccountTypes: BankAccountTypes,
                routingNumber: '',
                bankAccountNumber: '',
                confirmBankAccountNumber: '',
                accountHolderName: '',
                state: '',
                postalCode: '',
                accountNickname: '',
                bankAccountType: 'PERSONAL_CHECKING',
                submitting: false,
            }
        },
        mounted() {
            this.$logEvent('view_add_bank_account')
        },
        methods: {
            ...mapActions(['addPaymentSource']),
            addBankAccount: async function () {
                logger.info('AddBankAccount called')
                this.loading = true

                // Check if form is valid
                const isValid = await this.$refs.bankDetailForm.$refs.observer.validate()
                if (!isValid) {
                    this.loading = false
                    return
                }

                try {
                    await this.addPaymentSource({
                        bankAccountType: this.bankAccountType,
                        routingNumber: this.routingNumber,
                        bankAccountNumber: this.bankAccountNumber,
                        accountNickName: `${startCase(this.bankAccountType.toLowerCase())} (${this.bankAccountNumber.substr(-4)})`,
                    })
                    this.loading = false
                    if (this.$store.getters.error) {
                        logger.info(`Error adding bank account ${this.$store.getters.error}`)
                        this.$refs.bankDetailForm.applyGeneralError(i18n.t('pages.PaymentSources.AddPaymentSourceError'))
                        return
                    } else {
                        logger.info(`Successfully Added Bank Account`)
                        await this.$router.go(-1)
                    }
                } catch (error) {
                    this.loading = false
                    ApiErrorHandler(error)
                } finally {
                    this.loading = false
                }
            },
            validateAndSubmit: async function () {
                await this.$refs.bankDetailForm.validateAll()
                await this.addBankAccount()
            },
            applyGeneralError(errorMessage) {
                this.$refs.bankDetailForm.applyGeneralError(errorMessage)
            },
        },
    }
</script>
